<template>
  <div class="fleet-manager content">
    <div class="fleet-manager-header">
      <div>
        <i class="icon-button"
           :class="$config.icons.general.back"
           v-b-tooltip
           title="Back"
           @click="$router.back()"></i>
      </div>
      <div>
        <h1>Fleet Manager</h1>
      </div>
      <div></div>
    </div>
    <div class="fm-inner">
      <b-tabs>
        <b-tab title="Vehicles" class="fm-bg">
          <FleetManagementTable
              :show-fields="['selected', 'plant_num', 'device_code', 'device_name', 'registration', 'notes', 'actions']">
          </FleetManagementTable>
        </b-tab>
        <b-tab title="Servicing">
          <FleetManagementTable
              :show-fields="['selected', 'device_name', 'plant_num', 'odometer_reading', 'hours_tracked', 'service_mode', 'service_interval', 'last_service_date', 'next_service_date', 'actions']"
              :isServicing="true">
          >
          </FleetManagementTable>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>

import FleetManagementTable from '@/components/fleet-management/FleetManagementTable'

export default {
  name: 'fleet-manager',
  components: {
    FleetManagementTable
  },
  data: function () {
    return {
    }
  },
  created () {
  },
  methods: {
    goBack: function () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/variables';

  .fleet-manager {
    //padding: 20px;
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: $theme-color-background-2;
  }

  .fleet-manager-header {
    display: flex;
    flex-direction: row;
    font-size: 2em;
    justify-content: space-between;
    align-items: center;
    color: $text-color-heading;
    font-weight: 600;
    padding: 5px;
    background-color: $theme-color-background-2;
  }

  .fm-inner {
    padding: 5px;
    border-top: 1px solid darkgray;
    width: 100%;
    background: $theme-color-background-1;
    overflow: auto;
  }

  @media screen and (max-width: 600px) {
  }

</style>
