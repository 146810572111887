var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "fleet-manager content" }, [
    _c("div", { staticClass: "fleet-manager-header" }, [
      _c("div", [
        _c("i", {
          directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
          staticClass: "icon-button",
          class: _vm.$config.icons.general.back,
          attrs: { title: "Back" },
          on: {
            click: function($event) {
              return _vm.$router.back()
            }
          }
        })
      ]),
      _vm._m(0),
      _c("div")
    ]),
    _c(
      "div",
      { staticClass: "fm-inner" },
      [
        _c(
          "b-tabs",
          [
            _c(
              "b-tab",
              { staticClass: "fm-bg", attrs: { title: "Vehicles" } },
              [
                _c("FleetManagementTable", {
                  attrs: {
                    "show-fields": [
                      "selected",
                      "plant_num",
                      "device_code",
                      "device_name",
                      "registration",
                      "notes",
                      "actions"
                    ]
                  }
                })
              ],
              1
            ),
            _c(
              "b-tab",
              { attrs: { title: "Servicing" } },
              [
                _c(
                  "FleetManagementTable",
                  {
                    attrs: {
                      "show-fields": [
                        "selected",
                        "device_name",
                        "plant_num",
                        "odometer_reading",
                        "hours_tracked",
                        "service_mode",
                        "service_interval",
                        "last_service_date",
                        "next_service_date",
                        "actions"
                      ],
                      isServicing: true
                    }
                  },
                  [_vm._v(" > ")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("h1", [_vm._v("Fleet Manager")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }