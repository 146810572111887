<template>
  <div class="clickable editable-field" :class="{ 'form-error': $v.tempValue.$error && editMode, 'active': editMode }"
       @click="clickBody"
  >
    <span v-show="!editMode" ref="body" style="white-space: pre;" :class="{'hover-icon': !editMode}">
      {{displayText}}
    </span>
    <div class="edit-row" v-show="editMode" :style="'width: ' + bodyWidth + 'px;'">
      <b-select class="edit-input" v-model="$v.tempValue.$model" ref="input" v-if="type==='select'"
                :placeholder="placeholder" :options="options"></b-select>
      <textarea class="edit-input" v-model="$v.tempValue.$model" ref="input" @keypress="inputPress" v-else-if="type==='textarea'"
                :placeholder="placeholder"></textarea>

      <input class="edit-input" v-model="$v.tempValue.$model" ref="input" @keypress="inputPress" v-else
             :placeholder="placeholder" :type="inputType">
      <button @click="saveChange" class="save-button">
        <i class="fa fa-check"
           v-b-tooltip title="Save Changes"
        ></i>
      </button>
      <button @click="clickCancel" class="cancel-button">
        <i class="fa fa-times"
           v-b-tooltip title="Cancel"
        ></i>
      </button>
    </div>
    <div class="input-error" v-if="!$v.tempValue.required">
      <div class="input-error-pointer"></div>
      Cannot be blank.</div>
  </div>
</template>

<script>
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
import { updateDeviceProperty } from '@/components/helpers/DataProvider'
import * as AlertHelper from '@/components/helpers/AlertHelper'

export default {
  name: 'editable-field',
  props: [
    'deviceImei',
    'value', // Original Value passed by Parent
    'propName', // property name to use when updating device
    'type',
    'options',
    'validators',
    'settingsProp', // Indicates the value we're managing is part of 'settings', names of the attribute under settings
    'childProp', // Indicates we're being given an object and the name of the prop on that object to manage
    'placeholder',
  ],
  data: function () {
    return {
      editMode: false,
      tempValue: null,
      savedValue: null,
      bodyWidth: null
    }
  },
  validations () {
    if (this.validators) {
      return {
        tempValue: this.validators
      }
    } else {
      return {
        tempValue: {}
      }
    }
  },
  mounted: function () {
    this.resetValues(this.value)
  },
  methods: {
    clickBody (e) {
      e.stopPropagation()
      if (!this.editMode) {
        this.bodyWidth = this.$refs.body.clientWidth
        console.log(this.bodyWidth)
        this.editMode = true
        this.$nextTick(() => this.$refs.input.focus())
      }
    },
    clickCancel: function (e) {
      e.stopPropagation()
      this.resetValues(this.value)
      this.editMode = false
    },
    resetValues: function (val) {
      if (val && this.childProp) {
        this.tempValue = val[this.childProp]
        this.savedValue = val[this.childProp]
      } else {
        this.tempValue = val
        this.savedValue = val
      }
    },
    async saveChange (e) {
      e.stopPropagation()
      if (this.validators && this.$v.$anyError) {
        ErrorHelper.displayGeneralErrorToast('Some Fields Contain Invalid Data. Please fix them.', 'Invalid Fields')
      } else {
        let data
        let savePropName = this.propName
        if (this.settingsProp) {
          data = {}
          data[this.settingsProp] = { ...this.value }
          if (this.childProp) {
            data[this.settingsProp][this.childProp] = this.tempValue
          } else {
            data[this.settingsProp] = this.tempValue
          }
          savePropName = 'settings'
        } else {
          data = this.tempValue
        }
        let result = await updateDeviceProperty(this.deviceImei, savePropName, data)

        console.log('RESULT ', result)
        // Note: We're telling the parent to update the 'value' prop with the event.
        if (result.success === true) {
          AlertHelper.successToast(`Device property ${this.propName} updated successfully.`, 'Changes saved.')
          this.editMode = false
          this.$emit('save', {
            device_imei: this.deviceImei,
            prop_name: this.propName,
            new_value: this.tempValue
          })
        } else {
          ErrorHelper.displayDataErrorToast(result)
        }
      }
    },
    inputPress (e) {
      if (event.charCode === 13) {
        this.saveChange(e)
      }
    }
  },
  computed: {
    inputType () {
      return this.type || 'text'
    },
    displayText () {
      if (this.type === 'select') {
        let selectedOption = this.options.find(x => x.value === this.savedValue)
        return selectedOption ? selectedOption.text : this.savedValue
      } else {
        return this.savedValue || '-'
      }
    }
  },
  watch: {
    value: function (newVal) {
      console.log('Parent Value Changed: ', newVal)
      this.resetValues(newVal)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';

  .editable-field {
    position: relative;
    min-height: 1.6em;
    color: $theme-color-primary-3;
    display: flex;
    justify-content: center;
  }

  .editable-field.active {
    min-width: 120px;
    width: 100%;
  }

  .hover-icon:hover {
    text-decoration: underline;
  }

  .hover-icon:hover:after {
    position: absolute;
    content: '\f040';
    top: -5px;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
  }

  .edit-row{
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin: 5px;
    min-width: 90px;

    input {
      width: 100%;
      min-width: 80px;
      margin: 0;
      padding: 0;
      height: auto;
    }

    select {
      width: calc(100% + 3em);
      min-width: 80px;
      margin: 0;
      padding: 0;
      height: auto;
    }

    textarea {
      width: 100%;
      min-width: 80px;
      margin: 0;
      padding: 0;
      height: auto;
    }
  }
.save-button {
  display: flex;
  flex-flow: row wrap;
  align-content: center;
  justify-content: center;
  padding: 1px;
  font-size: 0.75em;
  color: green;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -2.5em;
  border: solid 2px green;
  width: 2em;
  height: 2em;
  z-index: 5;
}
.save-button:hover {
  color: #00e64d;
  border: solid 2px #00e64d;
}

  .cancel-button {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: center;
    padding: 1px;
    font-size: 0.75em;
    color: #d22b2b;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -4.5em;
    border: solid 2px #d22b2b;
    width: 2em;
    height: 2em;
    z-index: 5;
  }

.cancel-button:hover {
  color: #f64242;
  border: solid 2px #f64242;
}

.input-error {
  flex-flow: row nowrap;
  position: absolute;
  z-index: 10;
  background: $theme-color-background-2;
  bottom: calc(-100% - 0em);
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  border-radius: 10px;
  box-shadow: 5px 5px 10px 3px rgba(0, 0, 0, 0.8);
}

.input-error-pointer {
  position: absolute;
  z-index: 10;
  background: transparent;
  left: 50%;
  top: -1em;
  width: 0;
  height: 0;
  transform: translateX(-50%);
  border-bottom: 20px solid $theme-color-background-2;
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
}


</style>
