var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fleet-management-table" },
    [
      _c("loading-box", { attrs: { loading: _vm.loading } }),
      _c("div", { staticClass: "filter-bar" }, [
        _c("div", { staticClass: "control-bar justify-content-between" }, [
          _c(
            "div",
            { staticClass: "control-bar" },
            [
              _c(
                "b-dropdown",
                { attrs: { text: "Edit" } },
                [
                  _c("b-dropdown-header", [_vm._v("Edit Multiple Devices")]),
                  !_vm.selectedDevices.length
                    ? _c("b-dropdown-item", { attrs: { variant: "warning" } }, [
                        _vm._v(" Select Devices to use this menu. ")
                      ])
                    : _vm._e(),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: { disabled: !_vm.selectedDevices.length },
                      on: {
                        click: function($event) {
                          return _vm.showEditPropModal(
                            "plant_num",
                            "Plant",
                            "text",
                            null,
                            "Plant Name"
                          )
                        }
                      }
                    },
                    [_vm._v("Set Device Plant")]
                  ),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: { disabled: !_vm.selectedDevices.length },
                      on: {
                        click: function($event) {
                          return _vm.$bvModal.show(
                            "modal-edit-service-settings-" + _vm._uid
                          )
                        }
                      }
                    },
                    [_vm._v("Set Service Settings")]
                  )
                ],
                1
              ),
              _c(
                "b-dropdown",
                { attrs: { text: "Columns" } },
                [
                  _c("b-dropdown-header", [_vm._v("Visible Columns")]),
                  _c(
                    "b-dropdown-form",
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          on: { change: _vm.toggleSelectAllFields },
                          model: {
                            value: _vm.selectAllFields,
                            callback: function($$v) {
                              _vm.selectAllFields = $$v
                            },
                            expression: "selectAllFields"
                          }
                        },
                        [_vm._v("Select All")]
                      )
                    ],
                    1
                  ),
                  _vm._l(
                    _vm.masterFields.filter(function(x) {
                      return x.selectable
                    }),
                    function(field, idx) {
                      return _c(
                        "b-dropdown-form",
                        { key: idx },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              on: { change: _vm.changeSelectField },
                              model: {
                                value: field.selected,
                                callback: function($$v) {
                                  _vm.$set(field, "selected", $$v)
                                },
                                expression: "field.selected"
                              }
                            },
                            [_vm._v(_vm._s(field.label))]
                          )
                        ],
                        1
                      )
                    }
                  )
                ],
                2
              ),
              _vm.clipboardPropData
                ? _c(
                    "b-button",
                    {
                      directives: [
                        { name: "b-tooltip", rawName: "v-b-tooltip" }
                      ],
                      attrs: {
                        variant: "warning",
                        disabled: _vm.selectedDevices.length === 0,
                        title: "Paste Clipboard to Selected"
                      },
                      on: { click: _vm.pasteClipboardData }
                    },
                    [_vm._v("Paste Data")]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm.clipboardPropData
            ? _c("div", { staticClass: "clipboard" }, [
                _vm._v(" Copied: " + _vm._s(_vm.clipboardDescription) + " "),
                _c("i", {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true }
                    }
                  ],
                  staticClass: "clipboard-icon",
                  class: _vm.$config.icons.general.clearClipboard,
                  attrs: { title: "Clear Clipboard" },
                  on: { click: _vm.clearClipboardData }
                })
              ])
            : _vm._e()
        ]),
        _c(
          "div",
          { staticClass: "filters" },
          [
            _c(
              "b-input-group",
              { attrs: { size: "sm" } },
              [
                _c("b-form-input", {
                  staticClass: "text-input",
                  attrs: {
                    type: "search",
                    id: "filterInput",
                    placeholder: "Type to Search"
                  },
                  on: { keypress: _vm.inputPress },
                  model: {
                    value: _vm.filter,
                    callback: function($$v) {
                      _vm.filter = $$v
                    },
                    expression: "filter"
                  }
                }),
                _c(
                  "b-input-group-append",
                  [
                    _c(
                      "b-button",
                      {
                        on: {
                          click: function($event) {
                            _vm.showAdvanced = !_vm.showAdvanced
                          }
                        }
                      },
                      [_vm._v("Advanced")]
                    ),
                    _vm.filterMethod === "remote"
                      ? _c(
                          "b-button",
                          {
                            attrs: { variant: "primary" },
                            on: { click: _vm.applyFilter }
                          },
                          [_vm._v("Apply")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showAdvanced,
                    expression: "showAdvanced"
                  }
                ]
              },
              [
                _c("search-filters", {
                  attrs: { filters: _vm.filters },
                  on: {
                    change: _vm.advFilterChange,
                    "press-enter": _vm.applyFilter
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "overflow-auto background-2" },
        [
          _c(
            "b-table",
            {
              ref: "deviceTable",
              attrs: {
                striped: "",
                hover: "",
                "tbody-tr-class": "table-body-centered",
                items: _vm.deviceList,
                fields: _vm.deviceFields,
                filter: _vm.filterMethod === "local" ? _vm.filter : undefined,
                filterIncludedFields: _vm.filterOn,
                "current-page": _vm.currentPage,
                "per-page": _vm.perPage,
                "sort-by": _vm.sortBy
              },
              on: {
                "update:sortBy": function($event) {
                  _vm.sortBy = $event
                },
                "update:sort-by": function($event) {
                  _vm.sortBy = $event
                },
                filtered: _vm.filterChange,
                refreshed: _vm.onTableRefresh
              },
              scopedSlots: _vm._u([
                {
                  key: "head(selected)",
                  fn: function(row) {
                    return [
                      _c("b-form-checkbox", {
                        on: { change: _vm.toggleSelectAll },
                        model: {
                          value: _vm.selectAll,
                          callback: function($$v) {
                            _vm.selectAll = $$v
                          },
                          expression: "selectAll"
                        }
                      })
                    ]
                  }
                },
                {
                  key: "cell(selected)",
                  fn: function(row) {
                    return [
                      _c("b-form-checkbox", {
                        attrs: {
                          id: "selected-" + row.item.device_imei,
                          name: "selected-" + row.item.device_imei,
                          checked: _vm.isSelected(row.item)
                        },
                        on: {
                          change: function($event) {
                            return _vm.selectChange(row.item, $event)
                          }
                        }
                      })
                    ]
                  }
                },
                {
                  key: "cell(device_name)",
                  fn: function(row) {
                    return [
                      _vm.isEditable(row.item)
                        ? _c("editable-field", {
                            attrs: {
                              "device-imei": row.item.device_imei,
                              value: row.item.device_name,
                              "prop-name": "device_name",
                              placeholder: "Enter Name",
                              type: "text",
                              validators: _vm.nameValidators
                            },
                            on: { save: _vm.onPropUpdate }
                          })
                        : _c("span", [
                            _vm._v(" " + _vm._s(row.item.device_name) + " ")
                          ])
                    ]
                  }
                },
                {
                  key: "cell(plant_num)",
                  fn: function(row) {
                    return [
                      _vm.isEditable(row.item)
                        ? _c("editable-field", {
                            attrs: {
                              "device-imei": row.item.device_imei,
                              value: row.item.plant_num,
                              "prop-name": "plant_num",
                              type: "text"
                            },
                            on: { save: _vm.onPropUpdate }
                          })
                        : _c("span", [
                            _vm._v(" " + _vm._s(row.item.plant_num) + " ")
                          ])
                    ]
                  }
                },
                {
                  key: "cell(registration)",
                  fn: function(row) {
                    return [
                      _vm.isEditable(row.item)
                        ? _c("editable-field", {
                            attrs: {
                              "device-imei": row.item.device_imei,
                              value: row.item.registration,
                              "prop-name": "registration",
                              type: "text"
                            },
                            on: { save: _vm.onPropUpdate }
                          })
                        : _c("span", [
                            _vm._v(" " + _vm._s(row.item.registration) + " ")
                          ])
                    ]
                  }
                },
                {
                  key: "cell(odometer_reading)",
                  fn: function(row) {
                    return [
                      _vm.isEditable(row.item) &&
                      row.item.features.includes("device_trips")
                        ? _c("editable-field", {
                            attrs: {
                              "device-imei": row.item.device_imei,
                              value: row.item.odometer_reading,
                              "prop-name": "odometer_reading",
                              type: "number"
                            },
                            on: { save: _vm.onPropUpdate }
                          })
                        : !row.item.features.includes("device_trips")
                        ? _c("span", [_vm._v(" N/A ")])
                        : _c("span", [
                            _vm._v(
                              " " + _vm._s(row.item.odometer_reading) + " "
                            )
                          ])
                    ]
                  }
                },
                {
                  key: "cell(hours_tracked)",
                  fn: function(row) {
                    return [
                      _vm.isEditable(row.item) &&
                      row.item.features.includes("device_trips")
                        ? _c("editable-field", {
                            attrs: {
                              "device-imei": row.item.device_imei,
                              value: row.item.hours_tracked,
                              "prop-name": "hours_tracked",
                              type: "number"
                            },
                            on: { save: _vm.onPropUpdate }
                          })
                        : !row.item.features.includes("device_trips")
                        ? _c("span", [_vm._v(" N/A ")])
                        : _c("span", [
                            _vm._v(" " + _vm._s(row.item.hours_tracked) + " ")
                          ])
                    ]
                  }
                },
                {
                  key: "cell(overspeed_limit)",
                  fn: function(row) {
                    return [
                      _vm.isEditable(row.item)
                        ? _c("editable-field", {
                            attrs: {
                              "device-imei": row.item.device_imei,
                              value: row.item.overspeed_limit,
                              "prop-name": "overspeed_limit",
                              type: "number"
                            },
                            on: { save: _vm.onPropUpdate }
                          })
                        : _c("span", [
                            _vm._v(" " + _vm._s(row.item.overspeed_limit) + " ")
                          ])
                    ]
                  }
                },
                {
                  key: "cell(vehicle_dtcs)",
                  fn: function(row) {
                    return [
                      _vm.dtcHelper.hasDTCErrors(row.item)
                        ? _c("i", {
                            staticClass: "fa fa-exclamation-triangle dtc_codes",
                            attrs: { id: "dtc-icon" + row.item.device_id }
                          })
                        : _vm._e(),
                      _vm.dtcHelper.hasDTCErrors(row.item)
                        ? _c(
                            "b-tooltip",
                            {
                              attrs: {
                                target: "dtc-icon" + row.item.device_id,
                                noninteractive: "",
                                variant: "success",
                                placement: "left",
                                delay: {
                                  show: _vm.$config.general.tooltipShowDelay,
                                  hide: _vm.$config.general.tooltipHideDelay
                                }
                              }
                            },
                            [
                              _c("p", [_vm._v(" Pending Vehicle DTCs: ")]),
                              _c(
                                "ul",
                                _vm._l(
                                  _vm.dtcHelper.getDTCList(row.item),
                                  function(code, idx) {
                                    return _c(
                                      "li",
                                      {
                                        key: idx,
                                        staticClass: "field-container"
                                      },
                                      [_vm._v(" " + _vm._s(code) + " ")]
                                    )
                                  }
                                ),
                                0
                              ),
                              _c("p", [
                                _vm._v(
                                  "@ " +
                                    _vm._s(
                                      _vm.dtcHelper.formatDTCTimestamp(row.item)
                                    )
                                )
                              ])
                            ]
                          )
                        : _vm._e()
                    ]
                  }
                },
                {
                  key: "cell(notes)",
                  fn: function(row) {
                    return [
                      _vm.isEditable(row.item)
                        ? _c("editable-field", {
                            attrs: {
                              "device-imei": row.item.device_imei,
                              value: row.item.notes,
                              "prop-name": "notes",
                              type: "textarea"
                            },
                            on: { save: _vm.onPropUpdate }
                          })
                        : _c(
                            "span",
                            { staticStyle: { "white-space": "pre" } },
                            [_vm._v(" " + _vm._s(row.item.notes) + " ")]
                          )
                    ]
                  }
                },
                {
                  key: "cell(geolimit_settings)",
                  fn: function(row) {
                    return [
                      _c("div", { staticClass: "flex-row" }, [
                        row.item.geolimit_settings.is_active
                          ? _c("span", { staticClass: "geolimit-active" }, [
                              _vm._v("Active")
                            ])
                          : _vm._e(),
                        !row.item.geolimit_settings.is_active
                          ? _c("span", { staticClass: "geolimit-inactive" }, [
                              _vm._v("Inactive")
                            ])
                          : _vm._e(),
                        _c("i", {
                          directives: [
                            { name: "b-tooltip", rawName: "v-b-tooltip" }
                          ],
                          staticClass: "copy-button",
                          class: _vm.$config.icons.general.copy,
                          attrs: { title: "Copy Geolimit to Clipboard" },
                          on: {
                            click: function($event) {
                              return _vm.copyClipboardData(
                                "geolimit_settings",
                                row.item.geolimit_settings,
                                "Geolimit Settings from '" +
                                  row.item.device_name +
                                  "'."
                              )
                            }
                          }
                        })
                      ])
                    ]
                  }
                },
                {
                  key: "cell(last_service_date)",
                  fn: function(row) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex-row justify-content-center flex-nowrap"
                        },
                        [
                          row.item.features.includes("device_trips")
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.dt.timestampToLocalDate(
                                        row.item.last_service_date
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            : _c("span", [_vm._v("N/A")]),
                          row.item.service_mode && !row.item.last_service_date
                            ? _c("i", {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.interactive",
                                    value: false,
                                    expression: "false",
                                    modifiers: { interactive: true }
                                  }
                                ],
                                staticClass:
                                  "fa fa-exclamation-triangle row-icon-button-warning",
                                attrs: {
                                  title:
                                    "This vehicle has a Service Mode set,\n               but has never had a service event added."
                                }
                              })
                            : _vm._e(),
                          _vm.isEditable(row.item) &&
                          row.item.features.includes("device_trips")
                            ? _c("i", {
                                directives: [
                                  { name: "b-tooltip", rawName: "v-b-tooltip" }
                                ],
                                staticClass: "fa fa-plus add-button",
                                attrs: { title: "Add Service Event" },
                                on: {
                                  click: function($event) {
                                    return _vm.showAddService(row.item)
                                  }
                                }
                              })
                            : _vm._e()
                        ]
                      )
                    ]
                  }
                },
                {
                  key: "cell(service_mode)",
                  fn: function(row) {
                    return [
                      _vm.isEditable(row.item)
                        ? _c("editable-field", {
                            attrs: {
                              "device-imei": row.item.device_imei,
                              value: row.item.service_mode,
                              "prop-name": "service_mode",
                              type: "select",
                              options: _vm.serviceModeOptions
                            },
                            on: { save: _vm.onPropUpdate }
                          })
                        : _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.formatServiceMode(row.item.service_mode)
                                ) +
                                " "
                            )
                          ])
                    ]
                  }
                },
                {
                  key: "cell(service_interval)",
                  fn: function(row) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex-row justify-content-center flex-nowrap"
                        },
                        [
                          _vm.isEditable(row.item)
                            ? _c("editable-field", {
                                attrs: {
                                  "device-imei": row.item.device_imei,
                                  value: row.item.service_interval,
                                  "prop-name": "service_interval",
                                  type: "number"
                                },
                                on: { save: _vm.onPropUpdate }
                              })
                            : _c("span", [
                                _vm._v(
                                  " " + _vm._s(row.item.service_interval) + " "
                                )
                              ]),
                          row.item.service_mode && !row.item.service_interval
                            ? _c("i", {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.interactive",
                                    value: false,
                                    expression: "false",
                                    modifiers: { interactive: true }
                                  }
                                ],
                                staticClass:
                                  "fa fa-exclamation-triangle row-icon-button-warning",
                                attrs: {
                                  title:
                                    "This vehicle has a Service Mode set, but no Service Interval."
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "cell(next_service_date)",
                  fn: function(row) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex-row justify-content-center flex-nowrap"
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.dt.timestampToLocalDate(
                                  row.item.next_service_date
                                )
                              ) +
                              " "
                          ),
                          _vm.dt.isTimestampToday(row.item.next_service_date)
                            ? _c("i", {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.interactive",
                                    value: false,
                                    expression: "false",
                                    modifiers: { interactive: true }
                                  }
                                ],
                                staticClass:
                                  "fa fa-exclamation-triangle row-icon-button-danger",
                                attrs: {
                                  title:
                                    "The service for this vehicle is overdue!"
                                }
                              })
                            : _vm._e(),
                          row.item.service_mode &&
                          row.item.service_interval &&
                          !row.item.next_service_date
                            ? _c("i", {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.interactive",
                                    value: false,
                                    expression: "false",
                                    modifiers: { interactive: true }
                                  }
                                ],
                                staticClass:
                                  "fa fa-info-circle row-icon-button-info",
                                attrs: {
                                  title:
                                    "We had trouble estimating the next service for this vehicle. This usually happens when the vehicle is either new to the system or has not been driven recently."
                                }
                              })
                            : _vm._e()
                        ]
                      )
                    ]
                  }
                },
                {
                  key: "cell(actions)",
                  fn: function(row) {
                    return [
                      _c("i", {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true }
                          }
                        ],
                        staticClass: "row-icon-button row-action",
                        class: _vm.$config.icons.general.edit,
                        attrs: { title: "Edit Device" },
                        on: {
                          click: function($event) {
                            return _vm.deviceActionEdit(row.item)
                          }
                        }
                      }),
                      _c("i", {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true }
                          }
                        ],
                        staticClass: "row-icon-button row-action",
                        class: _vm.$config.icons.nav.fleet,
                        attrs: { title: "Device Page" },
                        on: {
                          click: function($event) {
                            return _vm.deviceActionFleet(row.item)
                          }
                        }
                      })
                    ]
                  }
                },
                {
                  key: "row-details",
                  fn: function(row) {
                    return [
                      _c(
                        "b-card",
                        [
                          _vm._v(" Device List Details "),
                          _c(
                            "b-button",
                            {
                              attrs: { size: "sm" },
                              on: { click: row.toggleDetails }
                            },
                            [_vm._v("Hide Details")]
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [_vm._v(" --> ")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flex-row justify-content-between table-bottom" },
        [
          _c("div", { staticClass: "row-count-text" }, [
            _vm._v("Total Devices: " + _vm._s(_vm.totalRows))
          ]),
          _c(
            "div",
            { staticClass: "page-bar" },
            [
              _c("b-form-select", {
                staticClass: "page-select",
                attrs: {
                  id: "perPageSelect",
                  size: "sm",
                  options: _vm.pageOptions
                },
                model: {
                  value: _vm.perPage,
                  callback: function($$v) {
                    _vm.perPage = $$v
                  },
                  expression: "perPage"
                }
              }),
              _c("b-pagination", {
                staticClass: "page-buttons",
                attrs: {
                  "total-rows": _vm.totalRows,
                  "per-page": _vm.perPage,
                  align: "fill",
                  size: "sm"
                },
                on: { change: _vm.onPageChange },
                model: {
                  value: _vm.currentPage,
                  callback: function($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage"
                }
              })
            ],
            1
          )
        ]
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "modal-edit-service-settings-" + _vm._uid,
            centered: "",
            size: "xl",
            "hide-footer": "",
            title: "Edit Device",
            "no-close-on-backdrop": ""
          }
        },
        [
          _vm.selectedDevices.length === 0
            ? _c("p", { staticClass: "warning-text" }, [
                _vm._v("You have not selected any devices to edit!")
              ])
            : _vm._e(),
          _vm.selectedDevices.length > 0
            ? _c("p", [
                _vm._v(
                  "Editing Service Settings for " +
                    _vm._s(_vm.selectedDevices.length) +
                    " devices. "
                )
              ])
            : _vm._e(),
          _vm.selectedReadOnly.length > 0
            ? _c("div", { staticClass: "warning-text" }, [
                _c("p", [
                  _vm._v(
                    "You do not WRITE permissions for one or more devices. Changing the service schedule for these devices will FAIL. "
                  )
                ]),
                _c("p", [
                  _vm._v(" Read Only Devices: "),
                  _c(
                    "ul",
                    _vm._l(_vm.selectedReadOnly, function(device, idx) {
                      return _c("li", { key: "ro-device-" + idx }, [
                        _vm._v(
                          " " +
                            _vm._s(device.device_code) +
                            " - " +
                            _vm._s(device.device_name) +
                            " "
                        )
                      ])
                    }),
                    0
                  )
                ])
              ])
            : _vm._e(),
          _c("div", { staticClass: "hint-box" }, [
            _c("h4", [_vm._v("Service Settings")]),
            _c("p", [
              _c("strong", [_vm._v("'Service Mode'")]),
              _vm._v(
                " decides how services are determined for the vehicle, either by distance (kilometers) or Hours (Runtime)."
              )
            ]),
            _c("p", [
              _c("strong", [_vm._v("'Service Interval'")]),
              _vm._v(
                " determines how many hours or kilometers there are before the device's next service is due."
              )
            ]),
            _c("p", [
              _vm._v(
                "Note: For service scheduling to work you also need to add an event for the vehicle's last service, so that the system knows where to start counting distance/hours from. You can do this by clicking the '+' sign in the 'Last Service Date' Column from the 'Servicing' view. "
              )
            ]),
            _c("strong", [_vm._v("For example:")]),
            _vm._v(
              " a common setting for a vehicle would be 'distance' mode at 5000km intervals. "
            )
          ]),
          _c(
            "div",
            { staticClass: "flex-column" },
            [
              _c("label", [_vm._v("Service Mode")]),
              _c("b-select", {
                attrs: { options: _vm.serviceModeOptions },
                model: {
                  value: _vm.setServiceType,
                  callback: function($$v) {
                    _vm.setServiceType = $$v
                  },
                  expression: "setServiceType"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { class: { "form-error": _vm.$v.setServiceFreq.$error } },
            [
              _c("label", [_vm._v("Service Interval")]),
              _c("b-input", {
                attrs: { type: "number" },
                model: {
                  value: _vm.$v.setServiceFreq.$model,
                  callback: function($$v) {
                    _vm.$set(_vm.$v.setServiceFreq, "$model", $$v)
                  },
                  expression: "$v.setServiceFreq.$model"
                }
              }),
              !_vm.$v.setServiceFreq.required
                ? _c("div", { staticClass: "input-error" }, [
                    _vm._v("Cannot be blank.")
                  ])
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "modal-footer" },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "warning" },
                  on: { click: _vm.applyServiceSettings }
                },
                [_vm._v("Apply")]
              ),
              _c(
                "b-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.$bvModal.hide(
                        "modal-edit-service-settings-" + _vm._uid
                      )
                    }
                  }
                },
                [_vm._v("Cancel")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "modal-edit-device-" + _vm._uid,
            centered: "",
            size: "lg",
            "hide-footer": "",
            title: "Edit Device"
          }
        },
        [
          _vm.currentDevice
            ? _c(
                "b-tabs",
                [
                  _c(
                    "b-tab",
                    { attrs: { title: "Properties" } },
                    [
                      _c("edit-device", {
                        attrs: {
                          "is-modal": "",
                          modal: "modal-edit-device-" + _vm._uid,
                          device: _vm.currentDevice,
                          editable: _vm.isEditable(_vm.currentDevice)
                        },
                        on: {
                          save: function($event) {
                            return _vm.onSave()
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    { attrs: { title: "Status" } },
                    [
                      _c("device-status", {
                        attrs: {
                          "is-modal": "",
                          modal: "modal-edit-device-" + _vm._uid,
                          device: _vm.currentDevice,
                          editable: _vm.isEditable(_vm.currentDevice)
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    { attrs: { title: "Alarms" } },
                    [
                      _c("device-alarms", {
                        attrs: {
                          "is-modal": "",
                          modal: "modal-edit-device-" + _vm._uid,
                          device: _vm.currentDevice,
                          editable: _vm.isEditable(_vm.currentDevice)
                        },
                        on: {
                          save: function($event) {
                            return _vm.onSave()
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    { attrs: { title: "Services" } },
                    [
                      _c("device-service-schedule", {
                        attrs: {
                          modal: "modal-edit-device-" + _vm._uid,
                          device: _vm.currentDevice,
                          editable: _vm.isEditable(_vm.currentDevice)
                        },
                        on: {
                          save: function($event) {
                            return _vm.onSave()
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    { attrs: { title: "Notifications" } },
                    [
                      _c("edit-user-notifications", {
                        attrs: { device: _vm.currentDevice }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    { attrs: { title: "Auditlog" } },
                    [
                      _c("h2", [_vm._v("Audit Log")]),
                      _c("audit-log", {
                        attrs: {
                          related_type: "device",
                          related_id: _vm.currentDevice.device_id
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "modal-simple-prop-edit-" + _vm._uid,
            centered: "",
            size: "l",
            "hide-footer": "",
            title: "Edit " + _vm.setPropTitle,
            "no-close-on-backdrop": ""
          }
        },
        [
          _vm.selectedDevices.length === 0
            ? _c("p", { staticClass: "warning-text" }, [
                _vm._v("You have not selected any devices to edit!")
              ])
            : _vm._e(),
          _vm.selectedDevices.length > 0
            ? _c("p", [
                _vm._v(
                  "Editing " +
                    _vm._s(_vm.setPropTitle) +
                    " for " +
                    _vm._s(_vm.selectedDevices.length) +
                    " devices. "
                )
              ])
            : _vm._e(),
          _vm.selectedReadOnly.length > 0
            ? _c("div", { staticClass: "warning-text" }, [
                _c("p", [
                  _vm._v(
                    "You do not WRITE permissions for one or more devices. Updating these devices will FAIL. "
                  )
                ]),
                _c("p", [
                  _vm._v(" Read Only Devices: "),
                  _c(
                    "ul",
                    _vm._l(_vm.selectedReadOnly, function(device, idx) {
                      return _c("li", { key: "ro-device-" + idx }, [
                        _vm._v(
                          " " +
                            _vm._s(device.device_code) +
                            " - " +
                            _vm._s(device.device_name) +
                            " "
                        )
                      ])
                    }),
                    0
                  )
                ])
              ])
            : _vm._e(),
          _c(
            "div",
            { class: { "form-error": _vm.$v.setServiceFreq.$error } },
            [
              _c("div", { staticClass: "green-label" }, [
                _vm._v(_vm._s(_vm.setPropTitle))
              ]),
              _c("b-input", {
                attrs: {
                  type: _vm.setPropType,
                  placeholder: _vm.setPropPlaceholder
                },
                model: {
                  value: _vm.setPropValue,
                  callback: function($$v) {
                    _vm.setPropValue = $$v
                  },
                  expression: "setPropValue"
                }
              }),
              !_vm.$v.setServiceFreq.required
                ? _c("div", { staticClass: "input-error" }, [
                    _vm._v("Cannot be blank.")
                  ])
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "modal-footer" },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "warning" },
                  on: { click: _vm.applyEditProp }
                },
                [_vm._v("Apply")]
              ),
              _c(
                "b-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.$bvModal.hide(
                        "modal-simple-prop-edit-" + _vm._uid
                      )
                    }
                  }
                },
                [_vm._v("Cancel")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "modal-add-service-event-" + _vm._uid,
            centered: "",
            size: "lg",
            "hide-footer": "",
            title: "Add Service Event",
            "no-close-on-backdrop": ""
          }
        },
        [
          _vm.currentDevice
            ? _c("DeviceAvatar", { attrs: { device: _vm.currentDevice } })
            : _vm._e(),
          _vm.currentDevice
            ? _c("DeviceEditEvent", {
                attrs: {
                  event: _vm.serviceEventTemplate,
                  device_imei: _vm.currentDevice.device_imei,
                  editable: "",
                  modal: "modal-add-service-event-" + _vm._uid
                },
                on: { save: _vm.refreshDeviceList }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }