<template>
  <div class="fleet-management-table">
    <loading-box v-bind:loading="loading"></loading-box>
    <div class="filter-bar">
<!--      Control Buttons -->
    <div class="control-bar justify-content-between">
      <div class="control-bar">
        <b-dropdown text="Edit">
          <b-dropdown-header>Edit Multiple Devices</b-dropdown-header>
          <b-dropdown-item variant="warning" v-if="!selectedDevices.length">
            Select Devices to use this menu.
          </b-dropdown-item>
          <b-dropdown-item :disabled="!selectedDevices.length"
              @click="showEditPropModal(
          'plant_num', 'Plant', 'text', null, 'Plant Name')">Set Device Plant</b-dropdown-item>
          <b-dropdown-item :disabled="!selectedDevices.length"
              @click="$bvModal.show(`modal-edit-service-settings-${_uid}`)">Set Service Settings</b-dropdown-item>
        </b-dropdown>
        <b-dropdown text="Columns">
          <b-dropdown-header>Visible Columns</b-dropdown-header>
          <b-dropdown-form>
            <b-form-checkbox v-model="selectAllFields" @change="toggleSelectAllFields">Select All</b-form-checkbox>
          </b-dropdown-form>
          <b-dropdown-form v-for="(field, idx) of masterFields.filter(x => x.selectable)" v-bind:key="idx">
            <b-form-checkbox v-model="field.selected" @change="changeSelectField">{{ field.label }}</b-form-checkbox>
          </b-dropdown-form>
        </b-dropdown>
        <b-button v-if="clipboardPropData" variant="warning" :disabled="selectedDevices.length === 0"
                  @click="pasteClipboardData" v-b-tooltip
                  title="Paste Clipboard to Selected"
        >Paste Data</b-button>
      </div>
      <div class="clipboard" v-if="clipboardPropData">
        Copied: {{clipboardDescription}}
        <i
            class="clipboard-icon"
            :class="$config.icons.general.clearClipboard"
            @click="clearClipboardData"
            v-b-tooltip.hover
            title="Clear Clipboard"
        ></i>
      </div>
    </div>
<!--      Filters -->
      <div class="filters">
        <b-input-group size="sm">
          <b-form-input
              v-model="filter"
              type="search"
              id="filterInput"
              placeholder="Type to Search"
              class="text-input"
              @keypress="inputPress"
          ></b-form-input>
          <b-input-group-append>
            <b-button @click="showAdvanced = !showAdvanced">Advanced</b-button>
            <b-button
                v-if="filterMethod === 'remote'"
                @click="applyFilter"
                variant="primary"
            >Apply</b-button
            >
          </b-input-group-append>
        </b-input-group>
        <div v-show="showAdvanced" class="">
          <search-filters
              :filters="filters"
              @change="advFilterChange"
              @press-enter="applyFilter"
          ></search-filters>
        </div>
      </div>
    </div>
    <!--    Bootstrap Table-->
    <div class="overflow-auto background-2">
      <b-table
        striped
        hover
        tbody-tr-class="table-body-centered"
        :items="deviceList"
        :fields="deviceFields"
        :filter="filterMethod === 'local' ? filter : undefined"
        :filterIncludedFields="filterOn"
        :current-page="currentPage"
        :per-page="perPage"
        :sort-by.sync="sortBy"
        v-on:filtered="filterChange"
        ref="deviceTable"
        @refreshed="onTableRefresh"
      >
        <!--Select All Header Element-->
        <template v-slot:head(selected)="row">
          <b-form-checkbox
            v-model="selectAll"
            v-on:change="toggleSelectAll"
          ></b-form-checkbox>
        </template>
        <!--Select Checkbox Row Element-->
        <template v-slot:cell(selected)="row">
          <b-form-checkbox
            :id="'selected-'+row.item.device_imei"
            :name="'selected-'+row.item.device_imei"
            :checked="isSelected(row.item)"
            v-on:change="selectChange(row.item, $event)"
          ></b-form-checkbox>
        </template>
        <!-- Name Row Element-->
        <template v-slot:cell(device_name)="row">
          <editable-field v-if="isEditable(row.item)"
            :device-imei="row.item.device_imei" :value="row.item.device_name" prop-name="device_name"
                          placeholder="Enter Name"
                          type="text" :validators="nameValidators"
                          @save="onPropUpdate"></editable-field>
          <span v-else>
            {{row.item.device_name}}
          </span>
        </template>
        <!-- Plant Num Row Element-->
        <template v-slot:cell(plant_num)="row">
          <editable-field v-if="isEditable(row.item)"
            :device-imei="row.item.device_imei" :value="row.item.plant_num" prop-name="plant_num" type="text"
                          @save="onPropUpdate"></editable-field>
          <span v-else>
            {{row.item.plant_num}}
          </span>
        </template>
        <!-- Rego Row Element-->
        <template v-slot:cell(registration)="row">
          <editable-field v-if="isEditable(row.item)"
            :device-imei="row.item.device_imei" :value="row.item.registration" prop-name="registration" type="text"
                          @save="onPropUpdate"></editable-field>
          <span v-else>
            {{row.item.registration}}
          </span>
        </template>
        <!-- Odometer Row Element-->
        <template v-slot:cell(odometer_reading)="row">
          <editable-field v-if="isEditable(row.item) && row.item.features.includes('device_trips')"
            :device-imei="row.item.device_imei" :value="row.item.odometer_reading" prop-name="odometer_reading"
                          type="number" @save="onPropUpdate"></editable-field>
          <span v-else-if="!row.item.features.includes('device_trips')">
            N/A
          </span>
          <span v-else>
            {{row.item.odometer_reading}}
          </span>
        </template>
        <!-- Hours Tracked Row Element-->
        <template v-slot:cell(hours_tracked)="row">
          <editable-field v-if="isEditable(row.item) && row.item.features.includes('device_trips')"
                          :device-imei="row.item.device_imei" :value="row.item.hours_tracked" prop-name="hours_tracked"
                          type="number" @save="onPropUpdate"></editable-field>
          <span v-else-if="!row.item.features.includes('device_trips')">
            N/A
          </span>
          <span v-else>
            {{row.item.hours_tracked}}
          </span>
        </template>
        <!-- Overspeed Limit Row Element-->
        <template v-slot:cell(overspeed_limit)="row">
          <editable-field v-if="isEditable(row.item)"
                          :device-imei="row.item.device_imei" :value="row.item.overspeed_limit"
                          prop-name="overspeed_limit" type="number"
                          @save="onPropUpdate"></editable-field>
          <span v-else>
            {{row.item.overspeed_limit}}
          </span>
        </template>
        <!--DTCs Row Element-->
        <template v-slot:cell(vehicle_dtcs)="row">
          <i v-if="dtcHelper.hasDTCErrors(row.item)"
             class="fa fa-exclamation-triangle dtc_codes" :id="'dtc-icon'+row.item.device_id"
          ></i>
          <b-tooltip v-if="dtcHelper.hasDTCErrors(row.item)"
                     :target="'dtc-icon'+row.item.device_id" noninteractive
                     variant="success" placement="left"
                     :delay="{ show: $config.general.tooltipShowDelay, hide: $config.general.tooltipHideDelay }">
            <p> Pending Vehicle DTCs: </p>
            <ul>
              <li v-for="(code, idx) of dtcHelper.getDTCList(row.item)" v-bind:key="idx" class="field-container">
                {{code}}
              </li>
            </ul>
            <p>@ {{ dtcHelper.formatDTCTimestamp(row.item)}}</p>
          </b-tooltip>
        </template>

        <!-- Notes Row Element-->
        <template v-slot:cell(notes)="row">
          <editable-field v-if="isEditable(row.item)"
                          :device-imei="row.item.device_imei" :value="row.item.notes" prop-name="notes" type="textarea"
                          @save="onPropUpdate"></editable-field>
          <span v-else style="white-space: pre;">
            {{row.item.notes}}
          </span>
        </template>

        <!-- Geolimit -->
        <template v-slot:cell(geolimit_settings)="row">
          <div class="flex-row">
            <span class="geolimit-active" v-if="row.item.geolimit_settings.is_active">Active</span>
            <span class="geolimit-inactive" v-if="!row.item.geolimit_settings.is_active">Inactive</span>
            <i
                class="copy-button"
                :class="$config.icons.general.copy"
                v-b-tooltip
                title="Copy Geolimit to Clipboard"
                @click="copyClipboardData('geolimit_settings', row.item.geolimit_settings,
              `Geolimit Settings from \'${row.item.device_name}\'.`)"
            ></i>
          </div>
        </template>
        <!-- Last Service Date Limit Row Element-->
        <template v-slot:cell(last_service_date)="row">
          <div class="flex-row justify-content-center flex-nowrap">
            <span v-if="row.item.features.includes('device_trips')">
              {{dt.timestampToLocalDate(row.item.last_service_date)}}
            </span>
          <span v-else>N/A</span>
            <i v-if="row.item.service_mode && !row.item.last_service_date"
               class="fa fa-exclamation-triangle row-icon-button-warning"
               v-b-tooltip.interactive="false" title="This vehicle has a Service Mode set,
               but has never had a service event added."
            ></i>
          <i v-if="isEditable(row.item) && row.item.features.includes('device_trips')"
             class="fa fa-plus add-button" v-b-tooltip title="Add Service Event"
             @click="showAddService(row.item)"></i>
          </div>
        </template>

        <!-- Service Mode-->
        <template v-slot:cell(service_mode)="row">
          <editable-field v-if="isEditable(row.item)"
                          :device-imei="row.item.device_imei" :value="row.item.service_mode"
                          prop-name="service_mode" type="select" :options="serviceModeOptions"
                          @save="onPropUpdate"></editable-field>
          <span v-else>
            {{formatServiceMode(row.item.service_mode)}}
          </span>
        </template>

        <!-- Service Interval-->
        <template v-slot:cell(service_interval)="row">
          <div class="flex-row justify-content-center flex-nowrap">
            <editable-field v-if="isEditable(row.item)"
                            :device-imei="row.item.device_imei" :value="row.item.service_interval"
                            prop-name="service_interval" type="number"
                            @save="onPropUpdate"></editable-field>
            <span v-else>
            {{row.item.service_interval}}
            </span>
            <i v-if="row.item.service_mode && !row.item.service_interval"
                class="fa fa-exclamation-triangle row-icon-button-warning"
                v-b-tooltip.interactive="false" title="This vehicle has a Service Mode set, but no Service Interval."
            ></i>
          </div>
        </template>

        <template v-slot:cell(next_service_date)="row">
          <div class="flex-row justify-content-center flex-nowrap">

            {{dt.timestampToLocalDate(row.item.next_service_date)}}

            <i v-if="dt.isTimestampToday(row.item.next_service_date)"
               class="fa fa-exclamation-triangle row-icon-button-danger"
               v-b-tooltip.interactive="false" title="The service for this vehicle is overdue!"
            ></i>
            <i v-if="row.item.service_mode && row.item.service_interval && !row.item.next_service_date"
               class="fa fa-info-circle row-icon-button-info"
               v-b-tooltip.interactive="false"
               title="We had trouble estimating the next service for this vehicle. This usually happens when the vehicle is either new to the system or has not been driven recently."
            ></i>
          </div>
        </template>

        -->
        <template v-slot:cell(actions)="row">
          <i
            class="row-icon-button row-action"
            :class="$config.icons.general.edit"
            @click="deviceActionEdit(row.item)"
            v-b-tooltip.hover
            title="Edit Device"
          ></i>
          <i
            class="row-icon-button row-action"
            :class="$config.icons.nav.fleet"
            v-b-tooltip.hover
            title="Device Page"
            @click="deviceActionFleet(row.item)"
          ></i>

        </template>
        <!--Device Details row-detail element-->
        <template v-slot:row-details="row">
          <b-card>
            Device List Details
            <b-button size="sm" @click="row.toggleDetails">Hide Details</b-button>
          </b-card>
        </template>
      </b-table>
    </div>

    <div class="flex-row justify-content-between table-bottom">
      <div class="row-count-text ">Total Devices: {{totalRows}}</div>
      <div class="page-bar">
        <b-form-select
            v-model="perPage"
            id="perPageSelect"
            size="sm"
            :options="pageOptions"
            class="page-select"
        >
        </b-form-select>
        <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            @change="onPageChange"
            align="fill"
            size="sm"
            class="page-buttons"
        ></b-pagination>
      </div>
    </div>

    <!--   Set Vehicle Service Schedule-->
    <b-modal
      :id="`modal-edit-service-settings-${_uid}`"
      centered
      class="modal-content"
      size="xl"
      hide-footer
      title="Edit Device"
      no-close-on-backdrop
    >
      <p v-if="selectedDevices.length === 0" class="warning-text">You have not selected any devices to edit!</p>
      <p v-if="selectedDevices.length > 0">Editing Service Settings for {{selectedDevices.length}} devices. </p>
      <div v-if="selectedReadOnly.length > 0" class="warning-text">
        <p >You do not WRITE permissions for one or more devices. Changing the
          service schedule for these devices will FAIL. </p>
        <p>
          Read Only Devices:
          <ul>
            <li v-for="(device, idx) of selectedReadOnly" v-bind:key="'ro-device-'+idx">
              {{device.device_code}} - {{device.device_name}}
            </li>
          </ul>
        </p>
      </div>
      <div class="hint-box">
        <h4>Service Settings</h4>
        <p><strong>'Service Mode'</strong> decides how services are determined for the vehicle, either by distance (kilometers) or Hours (Runtime).</p>
        <p><strong>'Service Interval'</strong> determines how many hours or kilometers there are before the device's next service is due.</p>
        <p>Note: For service scheduling to work you also need to add an event for the vehicle's last service,
          so that the system knows where to start counting distance/hours from. You can do this by clicking the '+' sign
          in the 'Last Service Date' Column from the 'Servicing' view.
        </p>

         <strong>For example:</strong> a common setting for a vehicle would be 'distance' mode at 5000km intervals.
      </div>
      <div class="flex-column">
        <label>Service Mode</label>
        <b-select v-model="setServiceType" :options="serviceModeOptions"></b-select>
      </div>
      <div :class="{ 'form-error': $v.setServiceFreq.$error}">
        <label>Service Interval</label>
        <b-input v-model="$v.setServiceFreq.$model" type="number"></b-input>
      <div class="input-error" v-if="!$v.setServiceFreq.required">Cannot be blank.</div>
      </div>
      <div class="modal-footer">
        <b-button variant="warning" @click="applyServiceSettings">Apply</b-button>
        <b-button @click="$bvModal.hide(`modal-edit-service-settings-${_uid}`)">Cancel</b-button>
      </div>
    </b-modal>

    <!--   Device Edit Modal Template-->
    <!--    Device Edit Modal Template-->
    <b-modal :id="`modal-edit-device-${_uid}`" centered class="modal-content" size="lg" hide-footer title="Edit Device">
      <b-tabs v-if="currentDevice">
        <b-tab title="Properties">
          <edit-device is-modal :modal="`modal-edit-device-${_uid}`" v-bind:device="currentDevice" v-on:save="onSave()" :editable="isEditable(currentDevice)"></edit-device>
        </b-tab>
        <b-tab title="Status">
          <device-status is-modal :modal="`modal-edit-device-${_uid}`" v-bind:device="currentDevice" :editable="isEditable(currentDevice)"></device-status>
        </b-tab>
        <b-tab title="Alarms">
          <device-alarms is-modal :modal="`modal-edit-device-${_uid}`" v-bind:device="currentDevice" v-on:save="onSave()" :editable="isEditable(currentDevice)"></device-alarms>
        </b-tab>
        <b-tab title="Services">
          <device-service-schedule :modal="`modal-edit-device-${_uid}`" v-bind:device="currentDevice" v-on:save="onSave()" :editable="isEditable(currentDevice)"></device-service-schedule>
        </b-tab>
        <b-tab title="Notifications">
          <edit-user-notifications v-bind:device="currentDevice"></edit-user-notifications>
        </b-tab>
<!--        <b-tab title="View Schedule">-->
<!--          <device-view-schedule is-modal :modal="`modal-edit-device-${_uid}`" v-bind:device="currentDevice"  v-on:save="onSave()" :editable="isEditable(currentDevice)"></device-view-schedule>-->
<!--        </b-tab>-->
<!--        <b-tab title="View Exclusions">-->
<!--          <device-view-exclusions is-modal :modal="`modal-edit-device-${_uid}`" v-bind:device="currentDevice"  v-on:save="onSave()" :editable="isEditable(currentDevice)"></device-view-exclusions>-->
<!--        </b-tab>-->
        <b-tab title="Auditlog">
          <h2>Audit Log</h2>
          <audit-log related_type="device" :related_id="currentDevice.device_id"></audit-log>
        </b-tab>
      </b-tabs>
    </b-modal>

    <!--   Simple Prop Update -->
    <b-modal
      :id="`modal-simple-prop-edit-${_uid}`"
      centered
      class="modal-content"
      size="l"
      hide-footer
      :title="`Edit ${setPropTitle}`"
      no-close-on-backdrop
    >
      <p v-if="selectedDevices.length === 0" class="warning-text">You have not selected any devices to edit!</p>
      <p v-if="selectedDevices.length > 0">Editing {{setPropTitle}} for {{selectedDevices.length}} devices. </p>
      <div v-if="selectedReadOnly.length > 0" class="warning-text">
        <p >You do not WRITE permissions for one or more devices. Updating these devices will FAIL. </p>
        <p>
          Read Only Devices:
        <ul>
          <li v-for="(device, idx) of selectedReadOnly" v-bind:key="'ro-device-'+idx">
            {{device.device_code}} - {{device.device_name}}
          </li>
        </ul>
        </p>
      </div>

      <div :class="{ 'form-error': $v.setServiceFreq.$error}">
        <div class="green-label">{{setPropTitle}}</div>
        <b-input v-model="setPropValue" :type='setPropType' :placeholder="setPropPlaceholder"></b-input>
        <div class="input-error" v-if="!$v.setServiceFreq.required">Cannot be blank.</div>
      </div>
      <div class="modal-footer">
        <b-button variant="warning" @click="applyEditProp">Apply</b-button>
        <b-button @click="$bvModal.hide(`modal-simple-prop-edit-${_uid}`)">Cancel</b-button>
      </div>
    </b-modal>

    <!--   Add Service Event -->
    <b-modal
        :id="`modal-add-service-event-${_uid}`"
        centered
        class="modal-content"
        size="lg"
        hide-footer
        :title="`Add Service Event`"
        no-close-on-backdrop
    >
      <DeviceAvatar v-if="currentDevice" :device="currentDevice"></DeviceAvatar>
      <DeviceEditEvent v-if="currentDevice" :event=serviceEventTemplate :device_imei="currentDevice.device_imei" editable
        :modal="`modal-add-service-event-${_uid}`" @save="refreshDeviceList"
      > </DeviceEditEvent>
    </b-modal>
  </div>
</template>
<script>
import * as DataProvider from '@/components/helpers/DataProvider'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
import * as AlertHelper from '@/components/helpers/AlertHelper'
import * as DTCHelper from '@/components/helpers/DTCHelper'
import {DateTimeHelper as dt} from '@/components/helpers/DateTimeHelper'
import {MetricFormatter as mf} from '@/components/helpers/MetricFormatter'
import LoadingBox from '@/components/helpers/LoadingBox'
import moment from 'moment'
import SearchFilters from '@/components/shared/SearchFilters'
import EditableField from '@/components/fleet-management/EditableField'
import { required, minLength, maxLength, minValue, maxValue } from 'vuelidate/lib/validators'
import DeviceAlarms from '@/components/device/DeviceAlarms.vue'
import DeviceViewSchedule from '@/components/device/DeviceViewSchedule.vue'
import AuditLog from '@/components/shared/AuditLogList.vue'
import EditUserNotifications from '@/components/notifications/EditUserNotifications.vue'
import DeviceStatus from '@/components/device/DeviceStatus.vue'
import EditDevice from '@/components/device/EditDevice.vue'
import DeviceServiceSchedule from '@/components/device/DeviceServiceSchedule.vue'
import DeviceViewExclusions from '@/components/device/DeviceViewExclusions.vue'
import DeviceEditEvent from "@/components/device/DeviceEditEvent.vue";
import DeviceAvatar from "@/components/device/DeviceAvatar.vue";

const requiredIfEnabled = (value, parent) => {
  if (parent.setServiceType !== 'disabled') {
    return (value !== '' && value !== null)
  } else {
    return true
  }
}

export default {
  name: 'fleet-management-table',
  components: {
    DeviceAvatar,
    DeviceEditEvent,
    DeviceViewExclusions,
    DeviceServiceSchedule,
    EditDevice,
    DeviceStatus,
    EditUserNotifications,
    AuditLog,
    DeviceViewSchedule,
    DeviceAlarms,
    EditableField,
    SearchFilters,
    LoadingBox
  },
  props: {
    showFields: Array,
    isServicing: {
      type: Boolean,
      default: false
    }
  },
  validations: {
    setServiceFreq: {
      requiredIfEnabled
    },
    setServiceMode: {}
  },
  data: function () {
    return {
      sortBy: null,
      dt: dt,
      loading: true,
      // Assignment Temporary variables
      dtcHelper: DTCHelper,
      tempOwner: null,
      // Table Filtering
      filterMethod: 'remote', // Filter Locally or remotely at the server-side
      filter: '',
      filterOn: [
        'imei',
        'device_id',
        'name',
        'plant_num',
        'registration',
        'device_type'
      ],
      // Table Pagination (local pagination - on the browser)
      currentPage: 1,
      totalRows: 0,
      perPage: 25,
      pageOptions: [
        { value: 25, text: '25' },
        { value: 50, text: '50' },
        { value: 100, text: '100' },
        { value: 0, text: 'All' }
      ],
      selectAll: false,
      currentDevice: null,
      filteredDeviceList: null,
      showAdvanced: false,
      selectable: false,
      // deviceFields: [],
      selectAllFields: false,
      masterFields: [
        {
          key: 'selected',
          label: '-',
          selected: true,
          mandatory: true
        },
        { key: 'device_imei',
          label: 'IMEI#',
          sortable: false,
          selectable: false,
          selected: false
        },
        { key: 'device_name',
          label: 'Name',
          sortable: true,
          selectable: true,
          selected: true,
          mandatory: true
        },
        { key: 'device_code',
          label: 'Code',
          sortable: true,
          selectable: true,
          selected: true
        },
        { key: 'device_type',
          label: 'Type',
          sortable: true,
          formatter: this.formatDeviceType,
          selectable: true,
          selected: true
        },
        { key: 'plant_num',
          label: 'Plant',
          sortable: true,
          selectable: true,
          selected: true
        },
        { key: 'registration',
          label: 'Rego',
          sortable: true,
          selectable: true,
          selected: true
        },
        { key: 'location.datetime',
          label: 'Last Report',
          sortable: true,
          formatter: mf.localDateTime,
          selectable: true,
          selected: false
        },
        { key: 'geolimit_settings',
          label: 'Geolimit',
          sortable: true,
          selectable: true,
          selected: false
        },
        { key: 'odometer_reading',
          label: 'Odometer',
          sortable: true,
          selectable: true,
          selected: false
        },
        { key: 'hours_tracked',
          label: 'Tracked Hours',
          sortable: true,
          selectable: true,
          selected: false
        },
        { key: 'overspeed_limit',
          label: 'Overspeed Limit',
          sortable: true,
          selectable: true,
          selected: false
        },
        { key: 'permissions',
          label: 'Permissions',
          sortable: true,
          selectable: true,
          selected: false,
          formatter: this.formatPermissions
        },
        { key: 'service_mode',
          label: 'Service Mode',
          sortable: true,
          selectable: true,
          fetchData: false,
          selected: false,
          formatter: this.formatServiceMode
        },
        { key: 'service_interval',
          label: 'Service Interval',
          sortable: true,
          selectable: true,
          fetchData: false,
          selected: false
        },
        { key: 'last_service_date',
          label: 'Last Service Date',
          sortable: true,
          formatter: dt.timestampToLocalDate,
          selectable: true,
          selected: false
        },
        { key: 'next_service_date',
          label: 'Est Next Service',
          sortable: true,
          formatter: dt.timestampToLocalDate,
          selectable: true,
          selected: false
        },
        { key: 'notes',
          label: 'Notes',
          sortable: false,
          selectable: true,
          selected: false
        },
        { key: 'vehicle_dtcs',
          label: 'DTCs',
          sortable: true,
          selectable: true,
          selected: false
        },
        {
          key: 'actions',
          label: 'Actions',
          selectable: true,
          selected: true
        }
      ],
      deviceList: [],
      // Advanced Filtering Options
      filterOpts: {},
      filters: [
        {
          label: 'Filter By Name',
          type: 'string',
          placeholder: 'Name Must Include...',
          fieldName: 'device_name'
        },
        {
          label: 'Filter By Code',
          type: 'string',
          placeholder: 'Device Code Must Include...',
          fieldName: 'device_code'
        },
        {
          label: 'Filter By Type',
          type: 'string',
          placeholder: 'Device type Must be...',
          fieldName: 'device_type'
        },
        {
          label: 'Filter By IMEI',
          type: 'string',
          placeholder: 'Device IMEI Must Include...',
          fieldName: 'device_imei'
        },
        {
          label: 'Filter By Rego',
          type: 'string',
          placeholder: 'Registration must include...',
          fieldName: 'registration'
        },
        {
          label: 'Filter By Plant',
          type: 'string',
          placeholder: 'Plant must include...',
          fieldName: 'plant_num'
        },
        {
          label: 'Filter by Icon',
          type: 'select',
          options: [
            { value: '', text: 'None' },
            { value: 'Car', text: 'Car' },
            { value: 'Boat', text: 'Boat' },
            { value: 'Caravan', text: 'Caravan' },
            { value: 'Excavator', text: 'Excavator' },
            { value: 'Motorbike', text: 'Motorbike' },
            { value: 'SemiTruck', text: 'SemiTruck' },
            { value: 'Tractor', text: 'Tractor' },
            { value: 'Truck', text: 'Truck' }
          ],
          fieldName: 'icon'
        },
        {
          label: 'is Geolimited',
          type: 'boolean',
          fieldName: 'geolimit_status'
        }

      ],
      loadingMoreDevices: false,
      deviceTypes: {
        device_sinocastel_213lu: 'SIN_213LU',
        device_benway_gv20: 'BEN_GV20',
        generic_object_tracker: 'GEN_OBJ',
        generic_vehicle_tracker: 'GEN_VEH',
        device_kingwo_nt06e: 'KGW_NT06E'
      },
      selectedDevices: [],
      nameValidators: {
        required
      },
      // Service Settings for Modal
      setServiceType: null,
      setServiceFreq: null,
      // Simple Prop Update Modal
      setPropKey: '',
      setPropTitle: '',
      setPropType: 'text',
      setPropValue: '',
      setPropPlaceholder: 'Enter Value',
      clipboardPropName: null,
      clipboardPropData: null,
      clipboardDescription: '',
      clipboardColour: '',
      deviceQueryFields: [
        'selected',
        'device_imei',
        'device_name',
        'device_code',
        'name',
        'device_id',
        'device_type',
        'plant_num',
        'icon',
        'registration',
        'location',
        'features',
        'last_service_date',
        'last_service_event',
        'next_service_date',
        'geolimit_settings',
        'odometer_reading',
        'hours_tracked',
        'overspeed_limit',
        'permissions',
        'service_mode',
        'service_interval',
        'notes',
        'vehicle_dtcs',
        'actions',
        'settings'
      ],
      serviceEventTemplate: {
        startTime: moment().format('HH:mm'),
        startDate: moment().toISOString(),
        event_subtype: 0,
        isNew: true,
        event_type: 5001,
        event_data: {}
      },
      serviceModeOptions: [
        {
          text: 'Disabled',
          value: null
        },
        {
          text: 'Distance (KMs)',
          value: 'distance'
        },
        {
          text: 'Runtime (Hours)',
          value: 'hours'
        }
      ],
      serviceShowMode: 'all'
    }
  },
  async mounted () {
    if (this.selectMode) {
      this.selectable = true
    }

    if (this.showFields) {
      this.masterFields.forEach(x => {
        x.selected = this.showFields.includes(x.key)
      })
    }
    // If a list of fields to display has been provided, filter to only show those fields.
    // this.deviceFields = this.masterFields
    await this.refreshDeviceList()
    this.loading = false
  },
  methods: {
    onSave: async function () {
      await this.refreshDeviceList()
    },
    toggleServiceFilter: function (mode) {
      this.serviceShowMode = mode
    },
    refreshDeviceList: async function () {
      if (this.deviceList.length === 0) {
        await this.fetchDeviceList()
      }
      if (this.filter || Object.keys(this.filterOpts).length > 0) {
        this.applyFilter()
      } else {
        this.$refs.deviceTable.refresh()
      }
    },
    fetchDeviceList: async function () {
      let resp = await DataProvider.getDeviceListPaginated({
        start: 0,
        // page_size: this.perPage,
        whitelist: this.deviceQueryFields
      })
      if (!resp.success) {
        ErrorHelper.displayDataErrorToast(resp)
      } else {
        this.totalRows = resp.data.total_devices
        this.deviceList = resp.data.devices
      }
    },
    updateTableFromJSON: function (deviceData) {
      this.$set(this, 'deviceList', deviceData)
    },
    deviceActionEdit: function (device) {
      console.log('DEVICE: ', device)
      this.currentDevice = device
      this.$bvModal.show(`modal-edit-device-${this._uid}`)
    },
    deviceActionFleet: function (device) {
      this.currentDevice = device
      let routeData = this.$router.resolve(`/device/${device.device_imei}`)
      window.open(routeData.href, '_blank')
    },
    onPageChange: function () {
      this.selectAll = false
    },
    onTableRefresh: function () {
      // Triggered whenever the table data is changed
      this.selectAll = this.$refs.deviceTable.localItems.every(x => this.selectedDevices.includes(x.device_imei))
    },
    toggleSelectAllFields: function (event) {
      // Note: We're not *really* allowing users to deselect everything, as this makes the BVTable display the data
      // without any column formatting
      if (event) {
        this.masterFields.forEach(x => { x.selected = (event && x.selectable) })
      } else {
        this.masterFields.forEach(x => { x.selected = (event || x.mandatory) })
      }
    },
    changeSelectField: function (event) {
      // Note: This looks weird, but we're checking that the event is to *select* a column (event === true) and
      // that only one column is not already selected.
      this.selectAllFields = event && (this.masterFields.filter(x => x.selectable && !x.selected).length === 1)
    },
    toggleSelectAll: function (event) {
      let selectedDevices
      if (this.selectAll) {
        this.selectedDevices = []
        this.selectAll = false
      } else {
        this.selectAll = true
        // If we already have devices selected from a previous page, add them to the selection.
        if (this.selectedDevices.length === 0) {
          this.selectedDevices = this.$refs.deviceTable.localItems.map(x => { return { ...x } })
        } else {
          this.$refs.deviceTable.localItems.forEach(x => {
            if (!this.selectedDevices.some(device => device.device_imei === x.device_imei)) {
              this.selectedDevices.push({ ...x })
            }
          })
          // this.selectedDevices = this.selectedDevices.concat(this.$refs.deviceTable.localItems.map(x => x.device_imei))
        }
      }
      this.$emit('change', selectedDevices)
    },
    selectChange: function (device, event) {
      device.selected = event // Manually update select state, otherwise result will be a 'click behind'
      // select a device
      if (event) {
        this.selectedDevices.push({ ...device })
        // unselect a device
      } else {
        this.selectedDevices = this.selectedDevices.filter(x => x.device_imei !== device.device_imei)
        this.selectAll = false
      }
    },
    isSelected: function (device) {
      return (this.selectedDevices.some(x => x.device_imei === device.device_imei))
    },
    isEditable: function (device) {
      if (device.permissions === null) {
        return true
      } else if (device.permissions) {
        return (device.permissions.writable === true || device.permissions.admin === true)
      } else {
        return false
      }
    },
    clearSelectAll: function () {
      if (Array.isArray(this.deviceList)) {
        this.deviceList.forEach(x => {
          x.selected = false
        })
      } else {
        this.$refs.deviceTable.localItems.forEach(x => {
          x.selected = false
        })
      }
      this.selectAll = false
    },
    /***
     * Handle Update Event from an Editable Field. Rather than refreshing the whole table, we just update the
     * prop of the device it mentions.
     * @param updateData
     */
    onPropUpdate: function (updateData) {
      this.$nextTick(() => {
        this.refreshDeviceList()
      })
    },
    filterChange: function (filteredDevices) {
      this.clearSelectAll()
      this.$emit('change', [])
      this.filteredDeviceList = filteredDevices
    },
    advFilterChange: function (newFilterOpts) {
      this.filterOpts = newFilterOpts
    },
    applyFilter: async function () {
      let responseData
      let fields = this.masterFields.map(x => x.key)
      if (this.showAdvanced) {
        responseData = await DataProvider.getFilteredDeviceList(
          this.filter,
          this.filterOpts,
          fields
        )
      } else {
        responseData = await DataProvider.getFilteredDeviceList(
          this.filter,
          null,
          fields
        )
      }
      // Should only be called when using 'remote' filtering, as control is hidden otherwise.
      if (!responseData.success) {
        ErrorHelper.displayDataErrorToast(responseData)
        return
      }
      // TODO - Check this format
      this.updateTableFromJSON(responseData.data)
      this.totalRows = responseData.data.length
    },
    /***
     * Handles 'Enter' key being pressed in search box.
     * @param event
     */
    inputPress: function (event) {
      if (event.charCode === 13) {
        this.applyFilter()
      }
    },
    filterEnter: function () {
      this.applyFilter()
    },
    /***
     * Display a modal for 'key' device property (shown as 'title' to the user) with an input box of 'type'.
     * The modal buttons will handle save and cancel operations.
     * @param key
     * @param title
     * @param type
     * @param defaultValue
     * @param placeholder
     */
    showEditPropModal: function (key, title, type, defaultValue = '', placeholder = 'Enter Value') {
      this.setPropKey = key
      this.setPropTitle = title
      this.setPropType = type
      this.setPropValue = defaultValue
      this.setPropPlaceholder = defaultValue
      this.$bvModal.show(`modal-simple-prop-edit-${this._uid}`)
    },
    /***
     * Save Device Property Changes from a 'showEditPropModal'.
     * @returns {Promise<void>}
     */
    applyEditProp: async function () {
      await this.saveDataMultiDevice(this.setPropKey, this.setPropValue)
      this.$bvModal.hide(`modal-simple-prop-edit-${this._uid}`)
    },
    /***
     * Update currently selected devices property 'key' to with 'data' value.
     * Also handles success/fail notification to the user via bvAlerts.
     * @param key
     * @param data
     * @returns {Promise<void>}
     */
    saveDataMultiDevice: async function (key, data) {
      let results = {}
      let errors = []
      for (let device of this.selectedDevices) {
        let result = await DataProvider.updateDeviceProperty(device.device_imei, key, data)
        if (result.success) {
          results[device.device_imei] = true
        } else {
          results[device.device_imei] = false
          console.error(result)
          errors.push(`Error setting service schedule for ${device.device_name}.`)
        }
      }
      if (!Object.keys(results).every(imei => results[imei])) {
        if (Object.keys(results).some(imei => results[imei])) {
          let failed = Object.keys(results).filter(x => !results[x])
          ErrorHelper.displayGeneralWarningToast(`Update failed for some devices. Failed Devices:
           ${this.formatIMEIstoDeviceCodes(failed)}`, 'Partial Success')
        } else {
          ErrorHelper.displayGeneralErrorToast(`Update failed for all devices!`, 'Operation Failed')
        }
      } else {
        AlertHelper.successToast('Update saved for all devices', 'Operation Successful')
      }
      setTimeout(() => this.$refs.deviceTable.refresh(), 100)
    },
    /***
     * Apply Selected Device Service Settings to currently selected devices.
     * Uses saveDataMultiDevice to save properties and notify the user of success/fail.
     * @returns {Promise<void>}
     */
    applyServiceSettings: async function () {
      console.log(`Applying Service Settings to ${this.selectedDevices.length} Devices as ${this.setServiceType} @
      ${this.setServiceFreq} frequency.`)
      let data
      if (this.setServiceType === 'disabled') {
        data = {
          service_settings: null
        }
      } else {
        data = {
          service_settings: {
            service_frequency: this.setServiceFreq,
            service_schedule_mode: this.setServiceType
          }
        }
      }
      await this.saveDataMultiDevice('settings', data)
      this.$bvModal.hide(`modal-edit-service-settings-${this._uid}`)
    },
    /***
     * Returns a user-readable device type string.
     * @param type
     * @returns {*}
     */
    formatDeviceType: function (type) {
      if (this.deviceTypes.hasOwnProperty(type)) {
        return this.deviceTypes[type]
      } else {
        return type
      }
    },
    formatPermissions: function (permissions) {
      if (!permissions) {
        return 'Owned'
      } else if (permissions.admin === true) {
        return 'Admin'
      } else if (permissions.writable === true) {
        return 'Write'
      } else if (permissions.readable === true) {
        return 'Read Only'
      } else {
        return 'None'
      }
    },
    formatServiceMode: function (mode) {
      let option = this.serviceModeOptions.find(x => x.value === mode)
      return option ? option.text : mode
    },
    formatIMEIstoDeviceCodes: function (deviceIMEIList) {
      return deviceIMEIList.map(deviceImei => this.selectedDevices.find(x => x.device_imei === deviceImei).device_code)
    },
    copyClipboardData: function (propName, propData, description, color = '#00a609') {
      this.clipboardPropName = propName
      this.clipboardPropData = propData
      this.clipboardColour = color
      this.clipboardDescription = description
      console.log('Clipboard Data: ', propData)
    },
    clearClipboardData: function () {
      this.clipboardPropName = null
      this.clipboardPropData = null
      this.clipboardDescription = ''
    },
    pasteClipboardData: function () {
      this.saveDataMultiDevice(this.clipboardPropName, this.clipboardPropData)
    },
    showAddService(device) {
      this.currentDevice = device
      this.$bvModal.show(`modal-add-service-event-${this._uid}`)
    }
  },
  computed: {
    selectedReadOnly: function () {
      // TODO - We're not really handling device perms for devices that are not currently loaded into the table.
      //        If this becomes a problem we could keep a local cache of them as they are selected (but that could
      //        lead to it's own issues.
      return this.selectedDevices.filter(device => {
        return !this.isEditable(device)
      })
    },
    deviceFields: function () {
      return this.masterFields.filter(x => x.selected)
    }
  },
  watch: {
    devices: function (newVal, oldVal) {
      this.updateTableFromJSON(newVal)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@/variables';

.fleet-management-table {
  background: $theme-color-background-1;
  flex-grow: 1;
}

.control-bar {
  background: $theme-color-background-1;
  display: flex;
  flex-direction: row;
  z-index: 5;
  flex-grow: 1;
}

.filter-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
}

.table-bottom {
  border-top: 1px solid darkgray;
}

.page-bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.row-action {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.row-count-text {
  color: white;
  font-weight: 500;
}

.advanced-filter {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 5px;
  padding: 3px;
  background: $theme-color-background-1;
  border: 1px solid $theme-color-primary-3;
}

.filter-label {
  font-family: "Open Sans", sans-serif;
  color: $theme-color-primary-3;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 0px 5px;
}

.sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  height: calc(1.5em + 0.5rem + 2px);
}

button {
  margin: 5px 2px;
}

.dropdown {
  margin: 5px 2px;
  z-index: 5;
}

li form {
  color: black;
}

.warning-text {
  color: yellow;
  font-style: italic;
}

.geolimit-active {
  color: #00e64d;
}

.geolimit-inactive {
  color: red;
}

.clipboard {
  justify-self: flex-end;
  color: $theme-color-primary-2;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  background: transparent;
  border: 2px solid green;
  border-radius: 10px;
}

.clipboard-item {
  padding: 0.375rem 0.75rem;
  border-radius: 5px;
}

.clipboard-icon {
  color: #e34343;
  cursor: pointer;
}
.clipboard-icon:hover {
  color: white;
}

.icon-button {
  margin: 0 4px;
  padding: 0;
}

.copy-button {
  font-size: 1em;
  margin-left: 0.2em;
  color: $theme-color-primary-3;
  cursor: pointer;
}

.copy-button:hover {
  color: white;
}

.edit-item {
  color: white;
}

.add-button {
  color: $theme-color-primary-3;
  cursor: pointer;
}

.add-button:hover {
  color: white;
}

i{
  margin-left: 0.2em;
}

</style>
